import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

export default function ContactLocationComponent() {
  return (
    <div className="bg-white shadow-custom-out">
      <div className="flex flex-col container max-w-screen-xl lg:pb-[60px] py-8 items-center">
        <div className="text-left text-red w-full py-6">
          <h2>Locations </h2>
        </div>

        <div className="flex flex-col md:flex-row justify-between w-full text-left spaxe-x-0 space-y-8 md:space-y-0 md:space-x-5">
          <div className="w-full md:w-1/3">
            <h4>Headquartes</h4>
            <ul className="space-y-3 md:space-y-1.5">
              <li className="py-1.5 flex items-start">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="mx-4  text-scarlet-red mt-2"
                />
                <p className="font-light text-xl md:text-lg">
                  2010 Diamond Hill Road, Woonsocket, RI 02895
                </p>
              </li>
              <li className="py-1.5 flex items-center">
                <span className="mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 fill-scarlet-red"
                    viewBox="0 0 384 512"
                  >
                    <path d="M97.3 507c-129.9-129.9-129.7-340.3 0-469.9 5.7-5.7 14.5-6.6 21.3-2.4l64.8 40.5a17.2 17.2 0 0 1 6.8 21l-32.4 81a17.2 17.2 0 0 1 -17.7 10.7l-55.8-5.6c-21.1 58.3-20.6 122.5 0 179.5l55.8-5.6a17.2 17.2 0 0 1 17.7 10.7l32.4 81a17.2 17.2 0 0 1 -6.8 21l-64.8 40.5a17.2 17.2 0 0 1 -21.3-2.4zM247.1 95.5c11.8 20 11.8 45 0 65.1-4 6.7-13.1 8-18.7 2.6l-6-5.7c-3.9-3.7-4.8-9.6-2.3-14.4a32.1 32.1 0 0 0 0-29.9c-2.5-4.8-1.7-10.7 2.3-14.4l6-5.7c5.6-5.4 14.8-4.1 18.7 2.6zm91.8-91.2c60.1 71.6 60.1 175.9 0 247.4-4.5 5.3-12.5 5.7-17.6 .9l-5.8-5.6c-4.6-4.4-5-11.5-.9-16.4 49.7-59.5 49.6-145.9 0-205.4-4-4.9-3.6-12 .9-16.4l5.8-5.6c5-4.8 13.1-4.4 17.6 .9zm-46 44.9c36.1 46.3 36.1 111.1 0 157.5-4.4 5.6-12.7 6.3-17.9 1.3l-5.8-5.6c-4.4-4.2-5-11.1-1.3-15.9 26.5-34.6 26.5-82.6 0-117.1-3.7-4.8-3.1-11.7 1.3-15.9l5.8-5.6c5.2-4.9 13.5-4.3 17.9 1.3z" />
                  </svg>
                </span>
                <p className="font-light text-xl md:text-lg">401-526-0055</p>
              </li>
              <li className="py-1.5 flex items-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mx-4  text-scarlet-red"
                />
                <p className="font-light text-xl md:text-lg">sales@ssllc.com</p>
              </li>
              <li className="py-1.5 flex items-center">
                <FontAwesomeIcon
                  icon={faClock}
                  className="mx-4  text-scarlet-red"
                />
                <p className="font-light text-xl md:text-lg">
                  9:00a – 5:00p ET, Mon. – Fri.
                </p>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h4>West Coast</h4>
            <ul className="space-y-3 md:space-y-1.5">
              <li className="py-1.5 flex">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="mx-4  text-scarlet-red mt-2"
                />
                <p className="font-light text-xl md:text-lg">
                  30220 Commerce Court, Murrieta, CA 92563
                </p>
              </li>
              <li className="py-1.5 flex items-center">
                <span className="mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 fill-scarlet-red"
                    viewBox="0 0 384 512"
                  >
                    <path d="M97.3 507c-129.9-129.9-129.7-340.3 0-469.9 5.7-5.7 14.5-6.6 21.3-2.4l64.8 40.5a17.2 17.2 0 0 1 6.8 21l-32.4 81a17.2 17.2 0 0 1 -17.7 10.7l-55.8-5.6c-21.1 58.3-20.6 122.5 0 179.5l55.8-5.6a17.2 17.2 0 0 1 17.7 10.7l32.4 81a17.2 17.2 0 0 1 -6.8 21l-64.8 40.5a17.2 17.2 0 0 1 -21.3-2.4zM247.1 95.5c11.8 20 11.8 45 0 65.1-4 6.7-13.1 8-18.7 2.6l-6-5.7c-3.9-3.7-4.8-9.6-2.3-14.4a32.1 32.1 0 0 0 0-29.9c-2.5-4.8-1.7-10.7 2.3-14.4l6-5.7c5.6-5.4 14.8-4.1 18.7 2.6zm91.8-91.2c60.1 71.6 60.1 175.9 0 247.4-4.5 5.3-12.5 5.7-17.6 .9l-5.8-5.6c-4.6-4.4-5-11.5-.9-16.4 49.7-59.5 49.6-145.9 0-205.4-4-4.9-3.6-12 .9-16.4l5.8-5.6c5-4.8 13.1-4.4 17.6 .9zm-46 44.9c36.1 46.3 36.1 111.1 0 157.5-4.4 5.6-12.7 6.3-17.9 1.3l-5.8-5.6c-4.4-4.2-5-11.1-1.3-15.9 26.5-34.6 26.5-82.6 0-117.1-3.7-4.8-3.1-11.7 1.3-15.9l5.8-5.6c5.2-4.9 13.5-4.3 17.9 1.3z" />
                  </svg>
                </span>
                <p className="font-light text-xl md:text-lg">401-526-0055</p>
              </li>
              <li className="py-1.5 flex items-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mx-4  text-scarlet-red"
                />
                <p className="font-light text-xl md:text-lg">sales@ssllc.com</p>
              </li>
              <li className="py-1.5 flex items-center">
                <FontAwesomeIcon
                  icon={faClock}
                  className="mx-4  text-scarlet-red"
                />
                <p className="font-light text-xl md:text-lg">
                  9:00a – 4:00p PT, Mon. – Fri.
                </p>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h4>Europe</h4>
            <ul className="space-y-3 md:space-y-1.5">
              <li className="py-1.5 flex">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="mx-4  text-scarlet-red mt-2"
                />
                <p className="font-light text-xl md:text-lg">
                  8 Allée du Château Sury, F-67550 Vendenheim, France
                </p>
              </li>
              <li className="py-1.5 flex items-center">
                <span className="mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 fill-scarlet-red"
                    viewBox="0 0 384 512"
                  >
                    <path d="M97.3 507c-129.9-129.9-129.7-340.3 0-469.9 5.7-5.7 14.5-6.6 21.3-2.4l64.8 40.5a17.2 17.2 0 0 1 6.8 21l-32.4 81a17.2 17.2 0 0 1 -17.7 10.7l-55.8-5.6c-21.1 58.3-20.6 122.5 0 179.5l55.8-5.6a17.2 17.2 0 0 1 17.7 10.7l32.4 81a17.2 17.2 0 0 1 -6.8 21l-64.8 40.5a17.2 17.2 0 0 1 -21.3-2.4zM247.1 95.5c11.8 20 11.8 45 0 65.1-4 6.7-13.1 8-18.7 2.6l-6-5.7c-3.9-3.7-4.8-9.6-2.3-14.4a32.1 32.1 0 0 0 0-29.9c-2.5-4.8-1.7-10.7 2.3-14.4l6-5.7c5.6-5.4 14.8-4.1 18.7 2.6zm91.8-91.2c60.1 71.6 60.1 175.9 0 247.4-4.5 5.3-12.5 5.7-17.6 .9l-5.8-5.6c-4.6-4.4-5-11.5-.9-16.4 49.7-59.5 49.6-145.9 0-205.4-4-4.9-3.6-12 .9-16.4l5.8-5.6c5-4.8 13.1-4.4 17.6 .9zm-46 44.9c36.1 46.3 36.1 111.1 0 157.5-4.4 5.6-12.7 6.3-17.9 1.3l-5.8-5.6c-4.4-4.2-5-11.1-1.3-15.9 26.5-34.6 26.5-82.6 0-117.1-3.7-4.8-3.1-11.7 1.3-15.9l5.8-5.6c5.2-4.9 13.5-4.3 17.9 1.3z" />
                  </svg>
                </span>
                <p className="font-light text-xl md:text-lg">
                  +33 3 88 56 30 06
                </p>
              </li>
              <li className="py-1.5 flex items-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mx-4  text-scarlet-red"
                />
                <p className="font-light text-xl md:text-lg">info@fameco.eu</p>
              </li>
              <li className="py-1.5 flex items-center">
                <FontAwesomeIcon icon={faClock} className="mx-4  text-scarlet-red" />
                <p className="font-light text-xl md:text-lg">
                  8:30a – 12:00p & 1:00p – 5:00p CET, Mon. – Fri.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
